<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_type"
            >
              <strong>
                From
              </strong>
            </label>
            <b-input
              v-model="date_from"
              type="month"
              @change="getLists"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_type"
            >
              <strong>
                To
              </strong>
            </label>
            <b-input
              v-model="date_to"
              type="month"
              @change="getLists"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-start align-items-center"
        >
          <iq-card class="shadow-none">
            <template v-slot:body>
              <h1>
                <strong>
                  ITEMS
                </strong>
              </h1>
            </template>
          </iq-card>
        </b-col>
        <b-col
          v-for="(type, key) in list.types"
          :key="key"
          cols="12"
          md="6"
        >
          <Card
            count-class="text-success"
            :count="parseFloat(type.item_histories[0]?.items_cost || 0.00).toFixed(2)"
            :label="type.type_name"
            :loading="fetching.types"
            :currency="true"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row class="mt-5">
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-start align-items-center"
        >
          <iq-card class="shadow-none">
            <template v-slot:body>
              <h1>
                <strong>
                  PERIPHERALS
                </strong>
              </h1>
            </template>
          </iq-card>
        </b-col>
        <b-col
          v-for="(category, key) in list.categories"
          :key="key"
          cols="12"
          md="6"
        >
          <Card
            count-class="text-secondary"
            :count="parseFloat(category.item_detail_histories[0]?.items_cost || 0.00).toFixed(2)"
            :label="category.category_name"
            :sub-label="category.type.type_name"
            :loading="fetching.categories"
            :currency="true"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminStatisticsService } from '@/services'
import formatter from '@/mixins/formatter'
import Card from '@/components/dashboard/Card'

export default {
  name: 'AdminDashboard',
  middleware: ['auth', 'admin'],
  components: {
    Card
  },
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },
  mixins: [formatter],
  data () {
    return {
      fetching: {
        types: false,
        categories: false
      },
      date_from: null,
      date_to: this.monthFormatter(Date.now()),
      list: {
        types: [],
        categories: []
      },
      totalAssetTypes: {
        series: [],
        labels: []
      },
      totalAssetCategories: {
        series: [],
        labels: []
      }
    }
  },
  mounted () {
    core.index()
    this.getLists()
  },
  methods: {

    async getTypeLists () {
      this.fetching.types = true
      return new Promise(resolve => {
        AdminStatisticsService.getTypeStatusCosts(
          this.objectToUrl({
            date_from: this.date_from,
            date_to: this.date_to
          })
        ).then(({ data }) => {
          this.list.types = data.map(type => {
            this.totalAssetTypes.labels.push(type.type_name)

            if (type.item_histories.length > 0) {
              this.totalAssetTypes.series.push(type.item_histories[type.item_histories.length]?.items_cost)
            }
            return type
          })

          this.fetching.types = false
          resolve(data)
        })
      })
    },

    async getCategoryLists (type = 'all') {
      this.fetching.categories = true
      this.totalAssetCategories.labels = []
      this.totalAssetCategories.series = []

      return new Promise(resolve => {
        AdminStatisticsService.getCategoryStatusCosts(
          this.objectToUrl({
            date_from: this.date_from,
            date_to: this.date_to,
            type: type,
            parent: 0
          })
        ).then(({ data }) => {
          this.list.categories = data.map(category => {
            this.totalAssetCategories.labels.push(category.category_name)
            if (category.item_detail_histories.length > 0) {
              this.totalAssetCategories.series.push(category.item_detail_histories[category.item_detail_histories.length]?.items_cost)
            }
            return category
          })

          this.fetching.categories = false
          resolve(data)
        })
      })
    },

    onClickLegendTypes (index) {
      this.getCategoryLists(this.list.types[index].id)
    },

    onClickTypes ({ dataPointIndex }) {
      this.getCategoryLists(this.list.types[dataPointIndex].id)
    },

    getLists () {
      this.getTypeLists().then(() => {
        this.getCategoryLists()
      })
    }
  }
}
</script>
