<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row
        class="mb-2"
      >
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_type"
            >
              <strong>
                Type
              </strong>
            </label>
            <b-select
              id="filter_type"
              v-model="filter.type"
              :options="filterTypes"
              :disabled="state.busy || fetching.filter.types"
              @change="onChangeFilterType"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Item
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.filter.item"
              :options="list.filter.items"
              type="text"
              label="item_label"
              placeholder="search item"
              :disabled="state.busy || fetching.filter.items"
              :loading="fetching.filter.items"
            >
              <template #option="{ item_name, serial_number }">
                <div class="d-flex flex-column">
                  <span>{{ item_name }}</span>
                  <strong>{{ serial_number }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItemDetails.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="tableItemDetails"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItemDetails.perPage"
        :current-page="tableItemDetails.currentPage"
        :items="myProvider"
        :fields="tableItemDetails.fields"
        :sort-by.sync="tableItemDetails.sortBy"
        :sort-desc.sync="tableItemDetails.sortDesc"
        :sort-direction="tableItemDetails.sortDirection"
        :filter="tableItemDetails.filter"
        :filter-included-fields="tableItemDetails.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItemDetails.currentPage * tableItemDetails.perPage - tableItemDetails.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell(item_detail_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div
            v-if="row.item.item"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowSwapFormItemDetail(row.item)"
            >
              Swap
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItemDetails.perPage"
              :options="tableItemDetails.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItemDetails.currentPage"
            :total-rows="tableItemDetails.totalRows"
            :per-page="tableItemDetails.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-swap-item-details"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Swap Item Details"
      @ok="onValidateSwapItemDetail"
    >
      <ValidationObserver
        ref="formSwapItemDetail"
      >
        <form
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Serial Number
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_serial_number"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="8"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Name
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_item_detail_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Type
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_type_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Category
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_category_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Purchase Date
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_purchase_date"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Supplier
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_supplier_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Brand
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_brand_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Original Cost
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.from_original_cost"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <label for="from_specifications">
                          <strong>
                            Specifications
                          </strong>
                        </label>
                        <b-textarea
                          id="from_specifications"
                          v-model="itemDetail.from_specifications"
                          rows="6"
                          max-rows="8"
                          placeholder="no specifications"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-row>

                    <!-- EMPLOYEE -->
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <h5>
                            <strong>
                              Employee
                            </strong>
                          </h5>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Code
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_employee_code"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          class="mb-3"
                          cols="12"
                          md="8"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Name
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_employee_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- ITEM -->
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <h5>
                            <strong>
                              Item
                            </strong>
                          </h5>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Serial Number
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_serial_number"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="8"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Name
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Type
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_type_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Category
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_category_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Brand
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.from_item_brand_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col cols="12 d-none d-md-block">
                  <hr>
                </b-col>
                <!-- FILLABLES -->
                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="status"
                        vid="from_status"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="from_status">
                            <strong>
                              Status
                            </strong>
                          </label>
                          <b-form-select
                            id="from_status"
                            v-model="itemDetail.from_status"
                            :options="list.itemDetail.statuses"
                            :state="errors.length > 0 ? false : null"
                            :disabled="state.busy || fetching.itemDetail.statuses"
                          >
                            <template #first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >
                                -- select status --
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="cost"
                        vid="from_status_cost"
                        rules="required"
                      >
                        <b-form-group>
                          <label
                            for="from_status_cost"
                          >
                            <strong>
                              Cost
                            </strong>
                          </label>
                          <b-input
                            id="from_status_cost"
                            v-model="itemDetail.from_status_cost"
                            type="number"
                            placeholder="enter cost"
                            step="0.01"
                            :state="errors.length > 0 ? false : null"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="remarks"
                        vid="from_remarks"
                        rules="required|max:500"
                      >
                        <b-form-group>
                          <label for="from_remarks">
                            <strong>
                              Remarks
                            </strong>
                          </label>
                          <b-textarea
                            id="from_remarks"
                            v-model="itemDetail.from_remarks"
                            rows="6"
                            max-rows="8"
                            placeholder="enter remarks"
                            :state="errors.length > 0 ? false : null"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="d-flex justify-content-end"
                    >
                      <div
                        class="form-group"
                      >
                        <b-form-checkbox
                          id="action"
                          v-model="itemDetail.from_action"
                          name="action"
                          value="Detached"
                          unchecked-value="Attached"
                          :disabled="state.busy"
                          switch
                        >
                          Check this if you want to detach the item
                        </b-form-checkbox>
                      </div>
                    </b-col>

                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="serial number"
                        vid="to_serial_number"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="to_serial_number">
                            <strong>
                              Serial Number
                            </strong>
                          </label>
                          <v-select
                            id="to_serial_number"
                            v-model="selected.itemDetail.swapToItemDetail"
                            type="text"
                            label="item_detail_code"
                            placeholder="search serial number here"
                            :class="[errors.length > 0 ? 'is-invalid' : null]"
                            :options="list.itemDetail.lists"
                            :disabled="state.busy || fetching.itemDetail.lists"
                            :loading="fetching.itemDetail.lists"
                          >
                            <template #option="{ serial_number, item_detail_code }">
                              <div class="d-flex flex-column">
                                <strong>{{ serial_number }}</strong>
                                <span>{{ item_detail_code }}</span>
                              </div>
                            </template>
                            <template #no-options="">
                              no available item detail
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="8"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Name
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_item_detail_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Type
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_type_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Category
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_category_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Purchase Date
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_purchase_date"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Supplier
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_supplier_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Brand
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_brand_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label>
                          <strong>
                            Original Cost
                          </strong>
                        </label>
                        <b-input
                          v-model="itemDetail.to_original_cost"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <label for="to_specifications">
                          <strong>
                            Specifications
                          </strong>
                        </label>
                        <b-textarea
                          id="to_specifications"
                          v-model="itemDetail.to_specifications"
                          rows="6"
                          max-rows="8"
                          placeholder="no specifications"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-row>

                    <!-- EMPLOYEE -->
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <h5>
                            <strong>
                              Employee
                            </strong>
                          </h5>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Code
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_employee_code"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          class="mb-3"
                          cols="12"
                          md="8"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Name
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_employee_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- ITEM -->
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <h5>
                            <strong>
                              Item
                            </strong>
                          </h5>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Serial Number
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_serial_number"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="8"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Name
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Type
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_type_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Category
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_category_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <b-form-group>
                            <label>
                              <strong>
                                Brand
                              </strong>
                            </label>
                            <b-input
                              v-model="itemDetail.to_item_brand_name"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12 d-none d-md-block">
                      <hr>
                    </b-col>

                    <!-- FILLABLES -->
                    <b-col
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="8"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="status"
                            vid="to_status"
                            rules="required"
                          >
                            <b-form-group>
                              <label for="to_status">
                                <strong>
                                  Status
                                </strong>
                              </label>
                              <b-form-select
                                id="to_status"
                                v-model="itemDetail.to_status"
                                :options="list.itemDetail.statuses"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy || fetching.itemDetail.statuses"
                              >
                                <template #first>
                                  <b-form-select-option
                                    :value="null"
                                    disabled
                                  >
                                    -- select status --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                          md="4"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="cost"
                            vid="to_status_cost"
                            rules="required"
                          >
                            <b-form-group>
                              <label
                                for="to_status_cost"
                              >
                                <strong>
                                  Cost
                                </strong>
                              </label>
                              <b-input
                                id="to_status_cost"
                                v-model="itemDetail.to_status_cost"
                                type="number"
                                placeholder="enter cost"
                                step="0.01"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy"
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="remarks"
                            vid="to_remarks"
                            rules="required|max:500"
                          >
                            <b-form-group>
                              <label for="to_remarks">
                                <strong>
                                  Remarks
                                </strong>
                              </label>
                              <b-textarea
                                id="to_remarks"
                                v-model="itemDetail.to_remarks"
                                rows="6"
                                max-rows="8"
                                placeholder="enter remarks"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy"
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col
                          cols="12"
                          class="d-flex justify-content-end invisible"
                        >
                          <div
                            class="form-group"
                          >
                            <b-form-checkbox
                              id="action"
                              name="action"
                              value="Detached"
                              unchecked-value="Attached"
                              disabled
                              switch
                            >
                              Hidden
                            </b-form-checkbox>
                          </div>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Swap Peripherals
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import {
  AdminItemDetailService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminSwapItemDetails',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Swap Item Details'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          items: false,
          types: false
        },
        itemDetail: {
          lists: false,
          types: false,
          categories: false,
          suppliers: false,
          statuses: false
        }
      },
      filter: {
        item: 'All',
        type: 'All'
      },
      selected: {
        filter: {
          item: null
        },
        itemDetail: {
          item: null,
          swapToItemDetail: null
        }
      },
      list: {
        filter: {
          types: [],
          items: []
        },
        itemDetail: {
          lists: [],
          items: [],
          statuses: []
        }
      },
      itemDetail: {
        from: null,
        from_serial_number: null,
        from_item_detail_name: null,
        from_type_name: null,
        from_category_name: null,
        from_purchase_date: null,
        from_supplier_name: null,
        from_brand_name: null,
        from_specifications: null,
        from_status: null,
        from_status_name: null,
        from_status_cost: 0.00,
        from_original_cost: null,
        from_remarks: null,
        from_action: null,
        from_item_serial_number: null,
        from_item_name: null,
        from_item_type_name: null,
        from_item_category_name: null,
        from_item_brand_name: null,
        from_item_employee_code: null,
        from_item_employee_name: null,
        to: null,
        to_serial_number: null,
        to_item_detail_name: null,
        to_type_name: null,
        to_category_name: null,
        to_purchase_date: null,
        to_supplier_name: null,
        to_brand_name: null,
        to_specifications: null,
        to_status: null,
        to_status_name: null,
        to_status_cost: 0.00,
        to_original_cost: null,
        to_remarks: null,
        to_action: null,
        to_item_serial_number: null,
        to_item_name: null,
        to_item_type_name: null,
        to_item_category_name: null,
        to_item_brand_name: null,
        to_item_employee_code: null,
        to_item_employee_name: null
      },
      tableItemDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          'index',
          'action',
          { key: 'item_detail_code' },
          { key: 'type_name', label: 'type' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_name' },
          { key: 'status_name', label: 'status' },
          { key: 'active' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    modalItemTitle () {
      return this.state.editing ? 'Edit Item Detail' : 'Add Item Detail'
    },
    filterTypes () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.types)
    }
  },

  watch: {

    'selected.filter.item' (item) {
      if (item) {
        this.filter.item = item.id
      } else {
        this.filter.item = 'All'
      }

      this.onRefreshTableItemDetails()
    },

    'itemDetail.from_status' (itemDetailStatus) {
      const status = this.list.itemDetail.statuses.find(status => status.value === itemDetailStatus)
      if (status) {
        this.itemDetail.costable = status.costable
        this.itemDetail.from_action = status.attachable === 1 ? 'Attached' : 'Detached'
      } else {
        this.itemDetail.costable = false
      }
    },

    'selected.itemDetail.swapToItemDetail' (itemDetail) {
      if (itemDetail) {
        this.itemDetail.to = itemDetail.id
        this.itemDetail.to_serial_number = itemDetail.serial_number
        this.itemDetail.to_item_detail_name = itemDetail.item_detail_name
        this.itemDetail.to_type_name = itemDetail.type_name
        this.itemDetail.to_category_name = itemDetail.category_name
        this.itemDetail.to_purchase_date = this.dateLongFormatter(itemDetail.purchase_date)
        this.itemDetail.to_supplier_name = itemDetail.supplier_name
        this.itemDetail.to_brand_name = itemDetail.brand_name
        this.itemDetail.to_original_cost = itemDetail.original_cost

        if (itemDetail.employee) {
          this.itemDetail.to_item_employee_code = itemDetail.employee.employee_code
          this.itemDetail.to_item_employee_name = `${itemDetail.employee.first_name} ${itemDetail.employee.last_name}`
        }

        if (itemDetail.item) {
          this.itemDetail.to_item_type_name = itemDetail.item.type_name
          this.itemDetail.to_item_category_name = itemDetail.item.category_name
          this.itemDetail.to_item_brand_name = itemDetail.item.brand_name
          this.itemDetail.to_item_name = itemDetail.item.item_name
          this.itemDetail.to_item_serial_number = itemDetail.item.serial_number
        } else {
          this.itemDetail.to_item_employee_code = null
          this.itemDetail.to_item_employee_name = null
          this.itemDetail.to_item_type_name = null
          this.itemDetail.to_item_category_name = null
          this.itemDetail.to_item_brand_name = null
          this.itemDetail.to_item_name = null
          this.itemDetail.to_item_serial_number = null
        }
      } else {
        this.itemDetail.to = null
        this.itemDetail.to_serial_number = null
        this.itemDetail.to_type_name = null
        this.itemDetail.to_category_name = null
        this.itemDetail.to_supplier_name = null
        this.itemDetail.to_status_name = null
        this.itemDetail.to_purchase_date = null
        this.itemDetail.to_item_employee_code = null
        this.itemDetail.to_item_employee_name = null
        this.itemDetail.to_item_type_name = null
        this.itemDetail.to_item_category_name = null
        this.itemDetail.to_item_brand_name = null
        this.itemDetail.to_item_name = null
        this.itemDetail.to_item_serial_number = null
      }
    }
  },

  mounted () {
    core.index()
    this.getFilterTypeLists().then(() => {
      this.onChangeFilterType(this.filter.type)
    })
  },

  methods: {
    async myProvider (ctx) {
      return await AdminItemDetailService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_item: this.filter.item,
          filter_type: this.filter.type,
          filter_attachable: 1
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterTypeLists () {
      this.fetching.filter.types = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTypes().then(({ data }) => {
          this.list.filter.types = data.map(({ id, type_name }) => ({ value: id, text: type_name }))
          this.fetching.filter.types = this.state.busy = false
          resolve(true)
        })
      })
    },

    async onChangeFilterType (type) {
      this.selected.filter.item = null
      this.fetching.filter.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems(`type=${type}`).then(({ data }) => {
          this.list.filter.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_name: item.item_name,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.filter.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemStatusLists () {
      this.fetching.itemDetail.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.itemDetail.statuses = data.map(({ id, status_name, costable, attachable }) => ({
            text: status_name,
            value: id,
            costable: costable,
            attachable: attachable
          }))
          this.fetching.itemDetail.statuses = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getSwappableItemDetails (detail, type, category) {
      this.fetching.itemDetail.lists = this.state.busy = true
      await SharedListService.getSwappableItemDetails(`detail=${detail}&type=${type}&category=${category}`).then(({ data }) => {
        this.list.itemDetail.lists = data
        this.fetching.itemDetail.lists = this.state.busy = false
      })
    },

    onRefreshTableItemDetails () {
      this.$refs.tableItemDetails.refresh()
    },

    async onShowSwapFormItemDetail (itemDetail) {
      this.itemDetail.from = itemDetail.id
      this.itemDetail.from_serial_number = itemDetail.serial_number
      this.itemDetail.from_item_detail_name = itemDetail.item_detail_name
      this.itemDetail.from_type_name = itemDetail.type_name
      this.itemDetail.from_category_name = itemDetail.category_name
      this.itemDetail.from_purchase_date = this.dateLongFormatter(itemDetail.purchase_date)
      this.itemDetail.from_supplier_name = itemDetail.supplier_name
      this.itemDetail.from_brand_name = itemDetail.brand_name
      this.itemDetail.from_original_cost = itemDetail.original_cost

      if (itemDetail.employee) {
        this.itemDetail.from_item_employee_code = itemDetail.employee.employee_code
        this.itemDetail.from_item_employee_name = `${itemDetail.employee.first_name} ${itemDetail.employee.last_name}`
      }

      if (itemDetail.item) {
        this.itemDetail.from_item_type_name = itemDetail.item.type_name
        this.itemDetail.from_item_category_name = itemDetail.item.category_name
        this.itemDetail.from_item_brand_name = itemDetail.item.brand_name
        this.itemDetail.from_item_name = itemDetail.item.item_name
        this.itemDetail.from_item_serial_number = itemDetail.item.serial_number
      }

      this.itemDetail.from_status = null
      this.itemDetail.from_status_cost = 0.00

      this.selected.itemDetail.swapToItemDetail = null

      this.itemDetail.to = null
      this.itemDetail.to_item_detail_name = null
      this.itemDetail.to_serial_number = null
      this.itemDetail.to_type_name = null
      this.itemDetail.to_category_name = null
      this.itemDetail.to_supplier_name = null
      this.itemDetail.to_brand_name = null
      this.itemDetail.to_status_name = null
      this.itemDetail.to_purchase_date = null
      this.itemDetail.to_item_employee_code = null
      this.itemDetail.to_item_employee_name = null
      this.itemDetail.to_item_type_name = null
      this.itemDetail.to_item_category_name = null
      this.itemDetail.to_item_brand_name = null
      this.itemDetail.to_item_name = null
      this.itemDetail.to_item_serial_number = null
      this.itemDetail.to_original_cost = 0.00

      this.getSwappableItemDetails(itemDetail.id, itemDetail.type_id, itemDetail.category_id).then(() => {
        this.getItemStatusLists()
      })

      this.$bvModal.show('modal-swap-item-details')
    },

    async onValidateSwapItemDetail (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formSwapItemDetail.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to swap this to item details?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              return this.onPostSwapItemDetail()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostSwapItemDetail () {
      return new Promise(resolve => {
        AdminItemDetailService.swap(this.itemDetail).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-swap-item-details')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableItemDetails.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formSwapItemDetail.setErrors(error.message)
          resolve(error)
        })
      })
    }

  }
}
</script>
<style>
  #modal-swap-item-details .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }
  #modal-swap-item-details .modal-content {
    min-height: 100vh;
  }
</style>
