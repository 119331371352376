<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <ValidationObserver
            ref="formChangePassword"
          >
            <form
              id="formChangePassword"
              novalidate
              @submit.prevent
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="current password"
                    vid="current_password"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="current_password">
                        <strong>
                          Current Password
                        </strong>
                      </label>
                      <b-input
                        id="current_password"
                        v-model="credentials.current_password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter current password"
                        :disabled="busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="new password"
                    vid="password"
                    rules="required|min:8|confirmed:password_confirmation"
                  >
                    <b-form-group>
                      <label for="password">
                        <strong>
                          New Password
                        </strong>
                      </label>
                      <b-input
                        id="password"
                        v-model="credentials.password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter new password"
                        :disabled="busy"
                        data-vv-as="password-confirmation"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >

                  <ValidationProvider
                    #default="{ errors }"
                    name="confirm password"
                    vid="password_confirmation"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="password">
                        <strong>
                          Confirm New Password
                        </strong>
                      </label>
                      <b-input
                        id="password_confirmation"
                        v-model="credentials.password_confirmation"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="confirm new password"
                        :disabled="busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="d-inline-block w-100">
                <b-button
                  variant="success"
                  :disabled="busy"
                  @click="onValidateChangePasswordForm"
                >
                  Update Password
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedAuthService } from '@/services'

export default {
  name: 'SharedChangePassword',

  middleware: ['auth'],

  metaInfo () {
    return {
      title: 'Change Password'
    }
  },

  data () {
    return {
      busy: false,
      credentials: {
        current_password: null,
        password: null,
        password_confirmation: null
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async onValidateChangePasswordForm () {
      await this.$refs.formChangePassword.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Do you really want to change password?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPostChangePasswordForm()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onPostChangePasswordForm () {
      this.busy = true
      return new Promise(resolve => {
        SharedAuthService.ChangePassword(this.credentials).then(({ data }) => {
          this.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.credentials.current_password = null
            this.credentials.password = null
            this.credentials.password_confirmation = null
            this.$refs.formChangePassword.reset()
          })
        }).catch(error => {
          this.busy = false
          this.$refs.formChangePassword.setErrors(error.message)
          resolve(error)
        })
      })
    }
  }

}
</script>
