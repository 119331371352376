import formatter from '@/mixins/formatter'
import store from '@/store'

export default {

  mixins: [formatter],

  data () {
    return {
      singleNotedByEmployees: [],
      singleIssuance: {
        id: null,
        employee_code: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        unit: null,
        department: null,
        position: null,
        employment_status: null,
        address: null,
        issuance_number: null,
        issuance_date: null,
        items: [],
        item_details: []
      },
      withCost: true
    }
  },

  methods: {

    marginY: (top = 0, bottom = 0) => ({ text: ' ', margin: [0, top, 0, bottom] }),

    pageBreak: (pageBreak = 'after') => ({ text: ' ', pageBreak: pageBreak }),

    createIssuancePDFOptions (options) {
      this.singleIssuance = options.employee
      this.singleNotedByEmployees = options.notedByEmployees

      this.withCost = options.withCost

      return this.$pdfmake.createPdf(
        {
          pageSize: 'A4',
          // pageOrientation: 'landscape',
          pageMargins: [40, 90, 40, 75],
          info: {
            title: options.info.title,
            author: options.info.author,
            creator: options.info.creator,
            subject: options.info.subject
          },
          defaultStyle: {
            font: 'Lato',
            fontSize: 10,
            alignment: 'justify',
            lineHeight: 1.5
          },
          header: [
            // {
            //   image: 'logo',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            //   fit: [90, 40]
            // },
            // {
            //   text: options.info.title,
            //   margin: [0, 5, 0, 0],
            //   alignment: 'center'
            // },
            {
              layout: 'noBorders',
              table: {
                headerRows: 1,
                widths: ['23.39%', '50%', '26.61%'],
                body: [
                  [
                    { rowSpan: 3, image: 'logo', fit: [100, 50] },
                    { colSpan: 1, text: process.env?.VUE_APP_COMPANY_TITLE?.toUpperCase(), fontSize: 10, alignment: 'center' },
                    { rowSpan: 3, text: `No. ${options.employee.issuance_number}`, alignment: 'right', margin: [10, 20] }
                  ],
                  [
                    '', { colSpan: 1, text: process.env.VUE_APP_COMPANY_ADDRESS, fontSize: 10, alignment: 'center' }, ''
                  ],
                  [
                    '', { colSpan: 1, text: options.info.title, alignment: 'center', fontSize: 15, bold: true }, ''
                  ]
                ]
              },
              margin: [35, 15]
            }
          ],
          content: [
            this._singleContents()
          ],
          footer: {
            stack: [
              { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
              { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
              {
                text: process.env.VUE_APP_COMPANY_TITLE,
                style: 'footer',
                margin: [40, 10, 0, 0]
              },
              { text: process.env.VUE_APP_COMPANY_ADDRESS, style: 'footer' },
              { text: process.env.VUE_APP_COMPANY_NUMBER, style: 'footer' }
            ]
          },
          images: {
            logo: options.logo ? options.logo : `${window.location.origin}/img/invalid-image.png`,
            invalidImage: `${window.location.origin}/img/invalid-image.png`
          },
          styles: {
            header: {
              margin: [40, 0, 0, 0]
            },
            footer: {
              fontSize: 9,
              margin: [40, 0, 0, 0]
            },
            title: {
              bold: true,
              alignment: 'center',
              fontSize: 12
            },
            subTitle: {
              bold: true,
              margin: [0, 10, 0, 10]
            },
            normalFont: {
              alignment: 'justify'
            }
          }
        },
        null,
        {
          Lato: {
            normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
            bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
            italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
            bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`
          }
        }
      )
    },

    _singleContents () {
      return {
        stack: [
          this._singleHeader(),
          this._singleBody(),
          this._singleAcknowledgement(),
          this._singleFooter()
        ]
      }
    },

    _singleHeader () {
      const noBorder = [false, false, false, false]

      const employeeName = `${this.singleIssuance.first_name} ${this.singleIssuance.middle_name ? this.singleIssuance.middle_name : ''} ${this.singleIssuance.last_name}`

      return {
        margin: [0, 3, 0, 3],
        table: {
          layout: 'headerLineOnly',
          headerRows: 1,
          widths: ['18%', '10%', '30%', '10%', '32%'],
          body: [
            [
              {
                text: 'REQUESTED BY:',
                bold: true,
                border: noBorder
              },
              {
                text: employeeName,
                border: noBorder,
                colSpan: 2
              },
              '',
              {
                text: 'DATE:',
                bold: true,
                border: noBorder
              },
              {
                // text: this.dateTimeLongFormatter(Date.now()),
                text: this.dateTimeLongFormatter(this.singleIssuance.issuance_date),
                border: noBorder
              }
            ],
            [
              {
                text: 'POSITION:',
                bold: true,
                border: noBorder
              },
              {
                text: this.singleIssuance.position,
                border: noBorder,
                colSpan: 4
              }
            ],
            [
              {
                text: 'DEPARTMENT/BRANCH:',
                bold: true,
                border: noBorder,
                colSpan: 2
              },
              '',
              {
                text: this.singleIssuance.department,
                border: noBorder,
                colSpan: 3
              }
            ]
          ]
        }
      }
    },

    _singleBody () {
      const border = [true, true, true, true]
      const allItems = []

      let totalCost = 0.00

      if (this.singleIssuance.items.length > 0) {
        this.singleIssuance.items.forEach(item => {
          const itemSerialNumber = item.serial_number ? `; S/N: ${item.serial_number}` : ''

          allItems.push(
            [
              { text: item.category_name, border: border },
              { text: 1, border: border, alignment: 'center' },
              { text: [item.specifications, itemSerialNumber], border: border, alignment: 'left' },
              { text: this.withCost ? this.numberFormatter(item.original_cost || '0.00') : '', alignment: 'right' }
            ]
          )

          totalCost = totalCost + parseFloat(item.original_cost || 0.00)

          this.singleIssuance.item_details.filter(
            itemDetail => itemDetail.item_id === item.item_id
          ).forEach(itemDetail => {
            const specifications = String(itemDetail.specifications || '').length > 10 ? String(itemDetail.specifications).replace(/\n/g, '; ') : `Brand: ${itemDetail.brand_name}`
            const detailSerialNumber = itemDetail.serial_number ? `; S/N: ${itemDetail.serial_number}` : ''

            allItems.push(
              [
                { text: itemDetail.category_name, border: border },
                { text: 1, border: border, alignment: 'center' },
                { text: [specifications, detailSerialNumber], border: border, alignment: 'left' },
                { text: this.withCost ? this.numberFormatter(itemDetail.original_cost || '0.00') : '', alignment: 'right' }
              ]
            )

            totalCost = totalCost + parseFloat(itemDetail.original_cost || 0.00)
          })
        })
      } else {
        this.singleIssuance.item_details.forEach(itemDetail => {
          const specifications = String(itemDetail.specifications || '').length > 10 ? String(itemDetail.specifications).replace(/\n/g, '; ') : `Brand: ${itemDetail.brand_name}`
          const detailSerialNumber = itemDetail.serial_number ? `; S/N: ${itemDetail.serial_number}` : ''

          allItems.push(
            [
              { text: itemDetail.category_name, border: border },
              { text: 1, border: border, alignment: 'center' },
              { text: [specifications, detailSerialNumber], border: border, alignment: 'left' },
              { text: this.withCost ? this.numberFormatter(itemDetail.original_cost || '0.00') : '', alignment: 'right' }
            ]
          )

          totalCost = totalCost + parseFloat(itemDetail.original_cost || 0.00)
        })
      }

      allItems.push(
        [
          { text: 'TOTAL', alignment: 'right', colSpan: 3 },
          '',
          '',
          { text: this.withCost ? this.numberFormatter(totalCost) : '', alignment: 'right' }
        ]
      )

      return {
        table: {
          layout: 'headerLineOnly',
          headerRows: 1,
          widths: ['20%', '5%', '62%', '13%'],
          body: [
            [
              {
                text: 'PARTICULAR',
                bold: true
              },
              {
                text: 'QTY',
                alignment: 'center',
                bold: true
              },
              {
                text: 'DESCRIPTION',
                bold: true
              },
              {
                text: 'UNIT COST ( IF APPLIED )',
                alignment: 'center',
                bold: true
              }
            ],
            ...allItems
          ]
        }
      }
    },

    _singleAcknowledgement () {
      return {
        margin: [0, 10, 0, 10],
        text: [
          'I acknowledge receipt of the tools/equipment listed above, which I will use responsibly and handle with care and prudence for the ',
          'duration of my employment with ', {
            text: 'Aljay Agro-Industrial Solutions Inc',
            bold: true
          },
          '. I agree to return these items in good, functional condition upon ',
          'separation. In the event of loss or damage due to negligence, I authorize ', {
            text: 'Aljay Agro-Industrial Solutions Inc',
            bold: true
          },
          '. to deduct the ',
          'corresponding cost from my salary or final pay. Should the amount exceed my final pay, I commit to settling the remaining balance in ',
          'CASH.'
        ]
      }
    },

    _singleFooter () {
      const user = store.getters['auth/user']

      const employeeName = `${this.singleIssuance.first_name} ${this.singleIssuance.middle_name ? this.singleIssuance.middle_name : ''} ${this.singleIssuance.last_name}`
      const noBorder = [false, false, false, false]

      const notedBy = this.singleNotedByEmployees.length > 0 ? {
        stack: [
          {
            text: 'Noted By:',
            bold: true
          },
          ...this.singleNotedByEmployees.map(notedByEmployee => ({
            stack: [
              {
                text: notedByEmployee.employee_name,
                margin: [0, 10, 0, 0],
                border: [false, false, true, false]
              },
              {
                text: notedByEmployee.position_name,
                margin: [0, 1, 0, 0]
              }
            ]
          }))
        ],
        border: noBorder
      } : {
        text: '',
        border: noBorder
      }

      return {
        margin: [0, 10, 0, 10],
        table: {
          layout: 'headerLineOnly',
          headerRows: 1,
          widths: ['33.333%', '33.3333%', '33.333%'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Issued By:',
                    bold: true
                  },
                  {
                    text: `${user.full_name}`,
                    margin: [0, 10, 0, 0]
                  }
                ],
                border: noBorder
              },
              notedBy,
              {
                stack: [
                  {
                    text: 'Received By:',
                    bold: true
                  },
                  {
                    text: employeeName,
                    margin: [0, 10, 0, 0]
                  },
                  {
                    text: this.singleIssuance.position,
                    margin: [0, 1, 0, 0]
                  }
                ],
                border: noBorder
              }
            ]
          ]
        }
      }
    }

  }
}
