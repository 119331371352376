<template>
  <div
    id="iq-sidebar"
    class="iq-sidebar"
  >
    <div
      class="iq-sidebar-logo d-flex justify-content-between align-items-center"
      style="min-height: 77px;"
    >
      <router-link :to="{ name: `${user ? user.role : 'admin'}.dashboard`}">
        <span>&nbsp;</span>
      </router-link>
      <div
        class="iq-menu-bt-sidebar"
        style="margin-top: 17px;"
      >
        <div class="iq-menu-bt align-self-center">
          <div
            class="wrapper-menu"
            @click="toggleSidebar"
          >
            <div class="main-circle">
              <i class="ri-arrow-left-s-line" />
            </div>
            <div class="hover-circle">
              <i class="ri-close-line" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <CollapseMenu
          :items="sidebarMenus"
          :open="true"
          :sidebar-group-title="true"
        />
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { core, APPNAME } from '@/config/pluginInit'
import { MAdmin, MUser } from '@/navigation'
import CollapseMenu from '@/components/core/menus/CollapseMenu'

export default {
  name: 'Sidebar',

  components: {
    CollapseMenu
  },

  data () {
    return {
      appName: APPNAME
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    sidebarMenus () {
      if (this.user === null) {
        return []
      }

      switch (this.user?.role) {
        case 'admin': {
          return MAdmin
        }
        case 'user': {
          return MUser
        }
        default: {
          return []
        }
      }
    }
  },

  mounted () {
    core.smoothScrollbar()
  },

  methods: {
    toggleSidebar () {
      core.triggerSet()
    }
  }
}
</script>
